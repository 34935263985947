<template>
  <div>
    <el-card>
      <el-button type="text" @click="$router.go(-1)">返回直播间分享员管理>>></el-button>
      <el-table :data="tablelist" v-loading="tableloading">
        <el-table-column prop="AddTime" label="操作时间"></el-table-column>
        <el-table-column prop="OperatorName" label="操作人"></el-table-column>
        <el-table-column prop="ImportCount" label="导入总数"></el-table-column>
        <el-table-column prop="SuccessCount" label="成功导入数量"></el-table-column>
        <el-table-column prop="ImportStateDisplay" label="状态"></el-table-column>
        <el-table-column prop="" label=" 操作">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.ImportState==0">报表生成中...</div>
              <el-button v-else type="text" @click="exportfail(scope.row)">下载失败数据</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  videoLiveSharerimportRecordList,
}from "@/api/sv1.0.0"
import config from "@/config"
export default {
  data () {
    return {
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      exporturl:config.EXPORT_URL,
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        let res = await videoLiveSharerimportRecordList(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    exportfail(row){
      let url = this.exporturl + '/pc/videoLiveSharer/importRecord/download?Id='+ row.Id
      // console.log(url)
      window.open(url)
    },
  }
}
</script>

<style>

</style>